import axios from 'axios'

const state = {
  accessProtocol: 'https:',
  accessIp: '192.168.1.20',
  accessPort: 8080,
  jwtToken: '',
  userId: 0,
  userName: '',
  roles: [],
  subscriptions: [],
  tokenExpiration: 0,
  accountValidity: '1970-01-01',
  axiosInstance: null,
  axiosInstanceUnsecure: null,
  blobAxios: null,
  rgpdHTML: "Vous pouvez accéder aux données vous concernant ou demander leur effacement. Vous disposez également d'un droit d’opposition, d’un droit de rectification et d’un droit à la limitation du traitement de vos données (cf. cnil.fr pour plus d’informations sur vos droits).  <br/><br/>  Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO).  <br/><br/>  Contacter notre DPO par voie électronique avec le lien nous contacter en bas de page.<br><br/>  Ou contacter notre DPO par courrier postal :<br/>  Amicale des Sapeurs Pompiers de Rennes<br>2 rue du moulin de Joue 35700 Rennes",
  stripeKey: 'pk_live_qhY7ikoz2OoGrvpfb89n5e8s00qEtQN6Os'
  //stripeKey: 'pk_test_mO4pBAkdMltp7dCZ2sNH2VfF002TDPviCC'
}

const mutations = {
  updateAccessProtocolMut(state, accessProtocol){
    state.accessProtocol = accessProtocol
  },
  updateAccessIpMut(state, addr){
    state.accessIp = addr
  },
  updateAccessPortMut(state, port){
    state.accessPort = port
    //state.accessPort= 8083
  },
  updateJwtToken(state, token){
    state.jwtToken = token
  },
  updateUserName(state, name){
    state.userName = name
  },
  updateSubscriptions(state, subscriptions){
    state.subscriptions = subscriptions
  },
  updateRoles(state, newRoles){
    if(newRoles instanceof Array){
      state.roles = newRoles
    }else if(newRoles.includes(',')){
      state.roles = newRoles.split(',')
    }else{
      state.roles = []
      state.roles.push(newRoles)
    }
  },
  updateAccountValidity(state, _validity){
    state.accountValidity = _validity
  },
  updateAxiosInstance(state, _instance){
    state.axiosInstance = _instance
  },
  updateAxiosInstanceUnsecure(state, _instance){
    state.axiosInstanceUnsecure = _instance
  },
  updateAxiosBlobInstance(state, _instance){
    state.blobAxios = _instance
  },
  updateUserId(state, id){
    state.userId = id
  },
  updateTokenExpirationMut(state, timestamp){
    state.tokenExpiration = timestamp
  },
  updateRGPD(state, rgpd){
    state.rgpdHTML = rgpd
  }
}

const actions = {
  updateAccessIp({ commit }, newAddr){
    commit('updateAccessIpMut', newAddr)
  },
  updateAccessPort({ commit }, newPort){
    commit('updateAccessPortMut', newPort)
  },
  updateTokenExpirationDate({ commit }, timestamp){
    commit('updateTokenExpirationMut', timestamp)
  },
  updateUser({ commit }, user){
    commit('updateJwtToken', user.token)
    commit('updateUserName', user.username)
    commit('updateRoles', user.roles)
    commit('updateUserId', user.userId)
    commit('updateSubscriptions', user.subscriptions)
    commit('updateAccountValidity', user.accountValidity)

    let instance = axios.create({
      baseURL: state.accessProtocol+'//'+state.accessIp+':'+state.accessPort+'/FireFighters/aspr/v1',
      //timeout: 1000,
      headers: {'Authorization': 'Bearer '+user.token}
    })
    commit('updateAxiosInstance', instance)

    let blobInstance = axios.create({
      baseURL: state.accessProtocol+'//'+state.accessIp+':'+state.accessPort+'/FireFighters/aspr/v1',
      responseType: 'blob',
      headers: {'Authorization': 'Bearer '+user.token}
    });
    commit('updateAxiosBlobInstance', blobInstance)
  },
  updateProtocolAndUnsecure({ commit }, protocol){
    commit('updateAccessProtocolMut', protocol)

    let instance = axios.create({
      baseURL: protocol+'//'+state.accessIp+':'+state.accessPort+'/FireFighters/aspr/v1'
    })
    commit('updateAxiosInstanceUnsecure', instance)
  },
  loadRGPD({ commit }){
    state.axiosInstanceUnsecure.get('/siteconfiguration/rgpd').then(response => {
      if(response && response.data)
        commit('updateRGPD', response.data)
    }).catch(e => {
      console.log(e)
    })
  },
  logOut({ commit }){
    commit('updateJwtToken', '')
    commit('updateUserName', '')
    commit('updateRoles', [])
    commit('updateAxiosInstance', '')
    commit('updateSubscriptions', [])
    commit('updateTokenExpirationMut', 0)
    commit('updateAccountValidity', '1970-01-01')
  }
}

const getters = {
  isAuthenticated: (state) => {
    if(state.jwtToken && state.userName){
      return true
    }
    return false
  },
  hasRole: (state) => (request) => {

    if(state.roles.includes('general_SUPER-ADMIN')){
      return true
    }

    let names = request.names
    let roles = request.roles
    let subscriptions = request.subscriptions

    if(subscriptions && state.subscriptions){
      for(let subscription of state.subscriptions){
        if(subscriptions.includes(subscription)){
          return true
        }
      }
    }

    if(names && roles){
      let roleNames = []
      for(let name of names){
        for(let role of roles){
          roleNames.push(name+'_'+role)
        }
      }

      for(let testRole of roleNames){
        //alert(state.roles[index])
        if(state.roles.includes(testRole))
          return true
      }

    }

    return false
  },
  parseJwt (token) {
    let strToken = token.jwtToken
    let base64Url = strToken.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    //return with keys: sub, iss, id, exp
    return JSON.parse(jsonPayload)
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
