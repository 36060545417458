<template>
  <div class="hello ">

    <div class="modal fade" id="viewNewsModal" tabindex="-1" role="dialog" aria-labelledby="viewNewsModal" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" v-html="curNews.title"></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body" v-if="curNews && curNews.id">
              <div class="row">
                <div class="col-md-4">
                  <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/news/'+curNews.id+'/file?token='+jwtToken" :alt="curNews.title" style="max-height: 500px;"/>
                </div>
                <div class="col-md-8">
                  <div class="ql-container ql-snow">
                    <div class="ql-editor newsDisplay" v-html="curNews.text">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2" v-if="curNews._links">
                <strong>Ecrit par:&nbsp;</strong>{{getPersonFromId({id: curNews._links.author.href.substring(7), callback: loadPerson}).lastName+' '+getPersonFromId({id: curNews._links.author.href.substring(7), callback: loadPerson}).firstName}}
                &nbsp;<strong>le:&nbsp;</strong> {{new Date(curNews.createdDate).toLocaleDateString()}}
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
            </div>

          </div>
        </div>
    </div>


    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h1>ACTUALITES DE L'AMICALE</h1>
        </div>
      </div>

      <br/>

      <div class="row" v-if="news && news[0]" style="cursor: pointer; text-align: center;">
        <div class="col-md-7" data-toggle="modal" data-target="#viewNewsModal" @click="curNews = news[0]">
          <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/news/'+news[0].id+'/file?token='+jwtToken" :alt="news[0].title" style="max-height: 500px;"/>
          <h2 v-html="news[0].title"></h2>
          <strong v-if="news[0].subTitle" v-html="news[0].subTitle.substring(0, 50)"></strong>
          <!--strong v-else-if="news[0].text" v-html="news[0].text.substring(0, 50)"></strong-->&nbsp;<a href="#">Plus...</a>
        </div>
        <div class="col-md-4 mr-3 pt-1" style="border-style: solid; border-width: 3px 0px 0px 0px">
          <h2>Actualités précédentes</h2>
          <div style="display: block;position: relative;max-height: 500px;overflow: auto;">
            <table class="table">
              <tbody>
                <tr v-for="pieceNews in news" :key="pieceNews.id" data-toggle="modal" data-target="#viewNewsModal" @click="curNews = pieceNews">
                  <td v-if="pieceNews.id != news[0].id" style="text-align: left;">
                    <h5 v-html="pieceNews.title"></h5>
                    <strong v-if="pieceNews.subTitle && pieceNews.subTitle.size>0" v-html="pieceNews.subTitle.substring(0, 50)"></strong>
                    <br/><a href="#">Plus...</a>
                  </td>
                  <td v-if="pieceNews.id != news[0].id">
                    <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/news/'+pieceNews.id+'/file?token='+jwtToken" :alt="pieceNews.title" style="height: 80px;"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br/>
      <br/>
      <br/>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      jwtToken: state => state.AppStore.jwtToken,
      news: state => state.NewsStore.news,
      userId: state => state.AppStore.userId
    }),
    ...mapGetters({
      getPersonFromId: 'PersonStore/getPersonFromId'
    })
  },
  methods: {
    ...mapActions({
        loadNews: 'NewsStore/loadNews',
        loadPerson: 'PersonStore/loadPerson'
    })
  },
  data: function() {
     return  {
        curNews: {}
    }
  },
  mounted() {
    this.loadNews({filters: 'page=0&limit=10', sort: 'sort=createdDate:desc'})
    this.getPersonFromId({id: this.userId, callback: this.loadPerson})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.newsDisplay img {
  max-width: 100%
}
</style>
