<template>
  <div class="calendarCodeDiv">
      <div class="col-md-12">
        <h1>Code Calendrier 2022</h1>
        <div class="row mx-auto">
          <qrcode-vue :value="refAddress" :size="size" level="H" class="center"></qrcode-vue>
        </div>
      </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'CalendarCode',
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort
    })
  },
  methods: {

  },
  data: function() {
     return  {
      refAddress: "",
      size: 300
    }
  },
  mounted: function(){
    if(this.$route.params.externalIdentifier){
      this.refAddress = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort+'/#/management/commissions/calendarCommandDetails/'+this.$route.params.externalIdentifier
    }else if(window.location.href.includes("/calendrier/code/")){
      this.refAddress = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort+'/#/management/commissions/calendarCommandDetails/'+window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    }
  },
  components: {
    QrcodeVue
  }
}
</script>
