import Vue from 'vue'
import App from './App.vue'

require('bootstrap')
var $ = require('jquery')
window.$ = $

import axios from 'axios'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Toasted from 'vue-toasted';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChild } from '@fortawesome/free-solid-svg-icons'
//import {faCalendarCheck} from '@fortawesome/free-solid-svg-icons'
import {faBoxOpen} from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {faChalkboard} from '@fortawesome/free-solid-svg-icons'
import {faChartLine} from '@fortawesome/free-solid-svg-icons'
import {faClipboardList} from '@fortawesome/free-solid-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import {faCubes} from '@fortawesome/free-solid-svg-icons'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faFile} from '@fortawesome/free-solid-svg-icons'
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {faFolder} from '@fortawesome/free-solid-svg-icons'
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'
import {faHome} from '@fortawesome/free-solid-svg-icons'
import {faIndustry} from '@fortawesome/free-solid-svg-icons'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {faKey} from '@fortawesome/free-solid-svg-icons'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import {faLockOpen} from '@fortawesome/free-solid-svg-icons'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faPrint} from '@fortawesome/free-solid-svg-icons'
import {faSort} from '@fortawesome/free-solid-svg-icons'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {faSortUp} from '@fortawesome/free-solid-svg-icons'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import {faTicketAlt} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {faTools} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'

//library.add(faCalendarCheck)
library.add(faBoxOpen)
library.add(faCalendarCheck)
library.add(faCheckCircle)
library.add(faChalkboard)
library.add(faChartLine)
library.add(faChild)
library.add(faCoffee)
library.add(faClipboardList)
library.add(faCubes)
library.add(faDownload)
library.add(faEdit)
library.add(faEnvelope)
library.add(faFile)
library.add(faFilePdf)
library.add(faFilter)
library.add(faFolder)
library.add(faFolderOpen)
library.add(faHome)
library.add(faIndustry)
library.add(faInfoCircle)
library.add(faKey)
library.add(faLock)
library.add(faLockOpen)
library.add(faPhone)
library.add(faPlus)
library.add(faPrint)
library.add(faShoppingBag)
library.add(faShoppingCart)
library.add(faSortUp)
library.add(faSortDown)
library.add(faSort)
library.add(faSyncAlt)
library.add(faTicketAlt)
library.add(faTimesCircle)
library.add(faTools)
library.add(faTrashAlt)
library.add(faUser)
library.add(faUserLock)
library.add(faUserSecret)
library.add(faUserShield)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import '@fortawesome/fontawesome-free/css/all.min.css'
//import '@fortawesome/fontawesome-free/js/all.min.js'
import FontAwesome from '@fortawesome/fontawesome-free'
Vue.use(FontAwesome)

import Vue2Editor from "vue2-editor"
Vue.use(Vue2Editor)

import VueRouter from 'vue-router'

import store from './stores'

//https://www.kaliop.com/en/blog-how-to-use-webpack-code-splitting-with-vue-js/
const CreateUser = () => import('@/components/CreateUser.vue')
import Home from './components/HelloWorld.vue'
//Management
const CalendarsManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */ '@/components/administration/commissions/CalendarsManagement.vue')
//import CalendarsCheck from './components/administration/commissions/CalendarsCheck.vue'
const ChildrenManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/commissions/ChildrenManagement.vue')
const CommissionWrapper = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/commissions/CommissionWrapper.vue')
const CommandsManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/CommandsManagement.vue')
const GeneralManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/general/GeneralAdministrationWrapper.vue')
const NewsManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/NewsManagement.vue')
const PersonsManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/PersonsManagement.vue')
const PhotosManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/PhotosManagement.vue')
//import ReservationDetailsComp from './components/administration/commissions/ReservationDetails.vue'
const ReservationsManagement = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "administration" */'@/components/administration/commissions/ReservationsManagement.vue')
//Commissions
const CalendarCommandDetails = () => import('@/components/commissions/CalendarCommandDetails.vue')
const CommissionGeneric = () => import('@/components/commissions/CommissionGeneric.vue')
//Account
const Account = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */ '@/components/account/Account.vue')
const AccountWrapper = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */ '@/components/account/AccountWrapper.vue')
const MyCommands = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */ '@/components/account/MyCommands.vue')
//import Rights from './components/account/Rights.vue'
//others
const CalendarCode = () => import('@/components/others/CalendarCode.vue')
const CalendarPurchase = () => import('@/components/others/CalendarPurchase.vue')
const Cart = () => import('@/components/others/Cart.vue')
const Command = () => import('@/components/others/CommandDetails.vue')
const CommandValidate = () => import('@/components/others/CommandValidate.vue')
const Photos = () => import('@/components/others/Photos.vue')
const Reservation = () => import('@/components/others/Reservation.vue')
const Documents = () => import('@/components/others/Documents.vue')
const Partners = () => import('@/components/others/Partners.vue')

Vue.config.productionTip = false

Vue.prototype.axios = axios

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('vue-router', VueRouter)
Vue.component('multiselect', Multiselect)

Vue.use(VueCtkDateTimePicker)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Toasted, {position: 'bottom-left', duration: 5000, action : {
    text : 'X',
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
}})


const routes = [
  { path: '/', name:'home', component: Home },
  { path: '/calendrier', name: 'calendrier', component: CalendarPurchase },
  { path: '/calendrier/code', name: 'codeCalendrier', component: CalendarCode },
  { path: '/createUser', component: CreateUser },
  { path: '/photos', component: Photos},
  { path: '/management/commands', component: CommandsManagement},
  { path: '/management/news', component: NewsManagement},
  { path: '/management/persons', component: PersonsManagement},
  { path: '/management/general', component: GeneralManagement },
  { path: '/management/photos', component: PhotosManagement},
  { path: '/management/commissions/calendars', component: CalendarsManagement},
  { path: '/management/commissions/calendarCommandDetails/:externalId', name: 'calendarCommandDetails', component: CalendarCommandDetails, props:true},
  //{ path: '/management/commissions/reservations/:commissionName', component: { default: ReservationsManagement, ReservationDetailsComp: ReservationDetailsComp }, props: {default: true, ReservationDetailsComp: true}},
  { path: '/management/commissions/commissionWrapper/:commissionName/:commissionId', component: CommissionWrapper, props: true},
  { path: '/management/commissions/reservations/:commissionName', component: ReservationsManagement, props: true},
  { path: '/management/commissions/children', component: ChildrenManagement},
  { path: '/reservation/:resourceId', name:'reservation', component: Reservation },
  { path: '/commission/:commissionName', name: 'commissionGeneric', component: CommissionGeneric, props: true},
  { path: '/cart', name:'cart', component: Cart },
  { path: '/myCommands/:personId', name: 'myCommands', component: MyCommands},
  { path: '/person/:personId', name: 'person', component: Account},
  { path: '/command/:commandId', name:'command', component: Command },
  { path: '/commandvalidate', name:'commandValidate', component: CommandValidate },
  { path: '/accountWrapper/:personId', name:'accountWrapper', component: AccountWrapper },
  { path: '/documents', name:'documents', component: Documents },
  { path: '/partners', name:'partners', component: Partners }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
