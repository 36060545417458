
const state = {
  persons: [],
  specLoadedPersons: {},
  isSearching: new Map()
}

const mutations = {
  updateList(state, _persons){
    state.persons = _persons
  },
  updateSpecLoadedPersons(state, _person){
    const tmpArr = state.specLoadedPersons;
    state.specLoadedPersons = [];
    tmpArr[_person.id] = _person;
    state.specLoadedPersons = tmpArr;
  },
}

const actions = {
  loadPersons({commit, rootState}, filterSort){
    let filters = ''
    if(filterSort){
      filters = '?'
      if(filterSort.filters){
          filters += filterSort.filters
          if(filterSort.sort)
            filters += '&'+filterSort.sort
      }else if(filterSort.sort){
        filters += filterSort.sort
      }
    }
    rootState.AppStore.axiosInstance.get('/users'+filters).then(response => {
      // JSON responses are automatically parsed.
      if(response.data._embedded) {
        commit('updateList', response.data._embedded.data)
      }
    })
    .catch(e => {
      console.log(e)
    })
  },
  loadPerson({state, commit, rootState}, personId){
    if(!state.isSearching.has(personId) && !state.specLoadedPersons[personId]){
      if(personId && personId != 0){
        const promise = rootState.AppStore.axiosInstance.get('/users/'+personId);
        state.isSearching.set(personId, promise)
        promise.then(response => {
          commit('updateSpecLoadedPersons', response.data)
          state.isSearching.delete(personId)
        })
        .catch(e => {
          console.log(e)
          commit('updateSpecLoadedPersons', {id: personId})
          state.isSearching.delete(personId)
        })        

        return promise
      }
    } else if(state.isSearching.has(personId)){
      return state.isSearching.get(personId);
    } else {
      return new Promise((resolve, reject) => {
        resolve({data: state.specLoadedPersons[personId]})
      })
    }
  }
}

const getters = {
  getPersonFromId: (state) => (loader) => {
    let person = state.persons.find(person => person.id == loader.id)
    if(!person)
      person = state.specLoadedPersons[loader.id]
    if(!person){
      if(loader.callback)
        loader.callback(loader.id)
      return {id: loader.id}
    }
    return person
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
