<template>
<div class="asprfooter mt-5" style="border-style: solid; border-width: 1px 0px 0px 0px">

  <div class="modal fade" id="emailContactModal" tabindex="-1" role="dialog" aria-labelledby="emailContactModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Formulaire de contact</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="form-group row">
              <label class="col-sm-4" for="contactEmailInput">Votre email</label>
              <div class="input-group col-sm-8">
                <div class="input-group-prepend">
                  <div class="input-group-text">@</div>
                </div>
                <input type="email" class="form-control" id="contactEmailInput" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="mail.email" required>
              </div>
              <small id="emailHelp" class="form-text text-muted mx-auto">Nous ne partagerons pas votre email</small>
            </div>
            <div class="form-group row">
              <label class="col-sm-4" for="contactInputPhone">Votre téléphone</label>
              <div class="input-group col-sm-8">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-phone"></i></div>
                </div>
                <input type="text" class="form-control" id="contactInputPhone" v-model="mail.phone" required>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4" for="contactLastNameInput">Votre nom</label>
              <div class="input-group col-sm-8">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-user"></i></div>
                </div>
                <input type="text" class="form-control" id="contactLastNameInput" v-model="mail.lastName" required>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4" for="contactFirstNameInput">Votre prénom</label>
              <div class="input-group col-sm-8">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-user"></i></div>
                </div>
                <input type="text" class="form-control" id="contactFirstNameInput" v-model="mail.firstName" required>
              </div>
            </div>
            <div class="form-group">
              <label for="cityInput">Sujet</label>
              <input type="text" class="form-control" id="subjectInput" v-model="mail.subject" required>
            </div>
            <div class="form-group">
              <label for="countryInput">Texte</label>
              <textarea class="form-control" id="textInput" v-model="mail.text" required></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
          <button
            v-if="processing"
            type="submit"
            class="btn btn-primary"
            disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Envoi en cours
          </button>
          <div v-else-if="mail.email && mail.phone && mail.lastName && mail.firstName && mail.subject && mail.text">
            <span class="mx-auto">
              <vue-recaptcha
                @verify="onCaptchaVerified"
                sitekey="6LcCyrsUAAAAAAl-zF0oTZnUyMV8o_IbMO86BPPS">
              </vue-recaptcha>
            </span>
          </div>
          <!--<button v-else type="button" class="btn btn-primary" @click="sendMail">Envoyer</button>-->
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="rgpdModal" tabindex="-1" role="dialog" aria-labelledby="rgpdModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Information sur les données personnelles</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-html="rgpdInfo">
        </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
          </div>
      </div>
    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3 dark-grey-text">

      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mb-4">
        <!-- Content -->
        <h6 class="text-uppercase font-weight-bold">
          Amicale des Sapeurs Pompiers de Rennes&nbsp;
          <a class="dark-grey-text" href="https://www.facebook.com/groups/436902130099904"><i class="fab fa-facebook"></i></a>&nbsp;
          <a class="dark-grey-text" href="https://www.instagram.com/amicalesprennes"><i class="fab fa-instagram"></i></a>
        </h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <!--p><a href="calendrier2021">Dons calendrier 2021</a></p-->
        <p>Toutes les informations sur ce site sont réservées aux membres
          de l’association Amicale des Sapeurs Pompiers de Rennes</p>
      </div>
      <!-- Grid column -->

      <!--
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a class="dark-grey-text" href="#!">MDBootstrap</a>
        </p>
        <p>
          <a class="dark-grey-text" href="#!">MDWordPress</a>
        </p>
        <p>
          <a class="dark-grey-text" href="#!">BrandFlow</a>
        </p>
        <p>
          <a class="dark-grey-text" href="#!">Bootstrap Angular</a>
        </p>

      </div>
      -->

      <!-- Grid column -->
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Crédits Photos</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          Rachel Le Guillou <a class="dark-grey-text" href="https://www.flickr.com/photos/150876194@N04"><i class="fab fa-flickr"></i></a>&nbsp;&nbsp;<a class="dark-grey-text" href="https://www.instagram.com/r_l_g_1_5"><i class="fab fa-instagram"></i></a>
        </p>
        <p>
          Eric Soret <a class="dark-grey-text" href="https://www.instagram.com/eric_photographies"><i class="fab fa-instagram"></i></a>
        </p>
        <!--p>
          <img src="@/assets/comes_design.png" width="20px"> C O M È S  D E S I G N <a class="dark-grey-text" href="https://www.graphiste-comesdesign.com"><i class="fas fa-link"></i></a>
        </p-->

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <i class="fas fa-home mr-3"></i>Amicale des Sapeurs Pompiers de Rennes<br/>
            2 rue du moulin de Joue
            35700 Rennes
        </p>
        <p>
          <i class="fas fa-envelope"></i>&nbsp;<a href="#" data-toggle="modal" data-target="#emailContactModal">Nous contacter</a> (par mail)
        </p>
        <p>
          <span style="font-size:15px;cursor: pointer;" data-toggle="modal" data-target="#rgpdModal"><i class="fas fa-user-shield"></i>&nbsp;<a href="#">Données Personnelles</a></span>
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

</div>

</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      rgpdInfo: state => state.AppStore.rgpdHTML,
      axiosInstanceUnsecure: state => state.AppStore.axiosInstanceUnsecure
    })
  },
  methods:{
    onCaptchaVerified: function (recaptchaToken) {
      this.mail.recaptcha = recaptchaToken
      this.processing = true
      this.sendMail()
    },
    sendMail(){
      this.processing = true
      if(!this.mail.email || !this.mail.phone || !this.mail.lastName || !this.mail.firstName || !this.mail.subject || !this.mail.text){
        this.$toasted.show("Veuillez remplir tous les champs", {type: 'info'})
        this.processing = false
        return
      }
      this.axiosInstanceUnsecure.post('/mails/sendinternalmail', this.mail).then(response => {
        this.$toasted.show("Votre email a bien été envoyé", {type: 'success'})
        this.processing = false
      })
      .catch(e => {
        this.$toasted.show("Erreur lors de l'envoi de l'email. Réessayez plus tard ", {type: 'error'})
        this.processing = false
      })
    }
  },
  data: function() {
     return  {
       processing: false,
       mail: {}
     }
  },
  components: {
    VueRecaptcha
  }
}
</script>
