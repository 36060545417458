
const state = {
  commissions: [],
  isLoading: null
}

const mutations = {
  updateList(state, _commissions){
    state.commissions = _commissions
  },
  updateListElem(state, _commission){
    var found = false
    for(var index = 0; index < state.commissions.length; index++) {
      const commission = state.commissions[index]
      if(commission.id === _commission.id) {
        found = true;
        if(!commission.description) {//no description: replace
          state.commissions[index] = _commission
        }
        break;
      }
    }
    if(!found) {
      state.commissions.push(_commission)
    }
  }
}

const actions = {
  loadCommissionsFast({commit, dispatch, rootState}, callback){
      rootState.AppStore.axiosInstanceUnsecure.get('commissions/light?size=25')
      .then(response => {
        // JSON responses are automatically parsed.
        if(response.data._embedded) {
          for(let commission of response.data._embedded.data){
            if(!commission.administrators)
              commission.administrators = []
            if(!commission.authorizedSubscribers)
              commission.authorizedSubscribers = []
            if(!commission.emailConfiguration)
              commission.emailConfiguration = {}
          }
          commit('updateList', response.data._embedded.data)
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  loadCommissions({commit, rootState}, callback){
    state.isLoading = rootState.AppStore.axiosInstance.get('commissions?size=25');
    
    state.isLoading.then(response => {
      // JSON responses are automatically parsed.
      if(response.data._embedded) {
        for(let commission of response.data._embedded.data){
          if(!commission.administrators)
            commission.administrators = []
          if(!commission.authorizedSubscribers)
            commission.authorizedSubscribers = []
          if(!commission.emailConfiguration)
            commission.emailConfiguration = {}
        }
        commit('updateList', response.data._embedded.data)
        state.isLoading = null;
      }
      if(callback)
        callback()
    })
    .catch(e => {
      console.log(e)
    })
  },
  loadCommissionFromName({state, commit, rootState}, commissionName){
    const commission = state.commissions.find(commission => commission.technicalName == commissionName);
    if(!commission || !commission.description){
      const promise = rootState.AppStore.axiosInstance.get('commissions?search=technicalName=='+commissionName)
      promise.then(emb => {
        commit('updateListElem', emb.data._embedded.data[0])
      })
      return promise
    } else {
      return new Promise((resolve, reject) => {
        resolve({"data": {"_embedded": {"data": [commission]}}})
      })
    }
  },
  loadCommissionFromId({state, commit, rootState}, commissionId){
    const commission = state.commissions.find(commission => commission.id == commissionId);
    if(!commission || !commission.description){
      const promise =  rootState.AppStore.axiosInstance.get('commissions/'+commissionId)
      promise.then(resp => {
        commit('updateListElem', resp.data)
      })
      return promise
    } else {
      return new Promise((resolve, reject) => {
        resolve({"data": commission})
      })
    }
  },
  forceLoadCommissionFromId({state, commit, rootState}, commissionId){
    const promise =  rootState.AppStore.axiosInstance.get('commissions/'+commissionId)
      promise.then(resp => {
        commit('updateListElem', resp.data)
      })
      return promise
  }
}

const getters = {
  getCommissionFromId: (state) => (id) => {
    let commission = state.commissions.find(commission => commission.id == id)
    if(!commission)
      return {id: id, emailConfiguration: {}}
    return commission
  },
  getCommissionFromName: (state) => (name) => {
    let commission = state.commissions.find(commission => commission.technicalName == name)
    if(!commission)
      return {id: 0}
    return commission
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
