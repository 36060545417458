<template>
  <div class="AccountConfirmationAndSubscriptionRenewalDiv">

    <div class="col-md-12 mt-n1">
      <h1 class="mx-n1 mt-n1 rounded-top">Gestion des ré-inscriptions</h1>

      <div class="col-md-12 mr-1 box">
        <h2>Validez vos données</h2>

        <div class="row">
          <div class="col-md-12">
            <div>
              <div class="form-group row">
                <label class="col-sm-4" for="firstNameInput">Prénom</label>
                <div class="input-group col-sm-8">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fas fa-user"></i></div>
                  </div>
                  <input disabled type="text" class="form-control" id="firstNameInput" v-model="person.firstName">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="nameInput">Nom</label>
                <div class="input-group col-sm-8">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fas fa-user"></i></div>
                  </div>
                  <input disabled type="text" class="form-control" id="nameInput" v-model="person.lastName">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="exampleInputEmailRe">Email</label>
                <div class="input-group col-sm-8">
                  <div class="input-group-prepend">
                    <div class="input-group-text">@</div>
                  </div>
                  <input v-if="person.id" type="email" class="form-control" id="exampleInputEmailRe" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="person.email" required>
                  <input v-else type="email" class="form-control" id="exampleInputEmailRe" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="person.email" required>
                </div>
                <small id="emailHelp" class="form-text text-muted mx-auto">Si vous changez votre email vous devrez le valider avant de procéder au paiement</small>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="exampleInputPhone">Téléphone</label>
                <div class="input-group col-sm-8">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fas fa-phone"></i></div>
                  </div>
                  <input type="text" class="form-control" id="exampleInputPhone" v-model="person.phone" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="birthDateInput">Date de naissance</label>
                <VueCtkDateTimePicker disabled class="col-sm-8" v-model="person.birthDate" only-date format='YYYY-MM-DD' no-button-now />
              </div>
              <div class="form-group row">
                <label class="col-sm-4" >CIS d'appartenance</label>
                <div class="dropdown col-sm-8">
                  <select class="custom-select" v-model="person.cis">
                    <option value="Autre">0-Autre</option>
                    <option value="Saint Georges">1-Saint Georges</option>
                    <option value="Beauregard">2-Beauregard</option>
                    <option value="Le Blosne">3-Le Blosne</option>
                    <option value="CTA-CODIS">4-CTA CODIS</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Adhésions</label>
                <span class="col-sm-8">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="AMICALE" value="AMICALE" v-model="person.subscriptions" disabled>
                    <label class="form-check-label" for="AMICALE">AMICALE</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="UDSP" value="UDSP" v-model="person.subscriptions">
                    <label class="form-check-label" for="UDSP">UDSP</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="FNSPF" value="FNSPF" v-model="person.subscriptions">
                    <label class="form-check-label" for="FNSPF">FNSPF (FEDE)</label>
                  </div>
                </span>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="newsLettersOptIn">Adhérent UD/FNSPF en double statut SPV/SPP</label>
                <div class="col-sm-8" style="text-align: left;">
                  <span class="custom-control custom-switch" style="display: inline" id="udFnspfDoubleStatusSubscription">
                    <input type="checkbox" class="custom-control-input" id="udFnspfDoubleStatusSubscriptionSwitch" v-model="person.udFnspfDoubleStatusSubscription">
                    <label class="custom-control-label" for="udFnspfDoubleStatusSubscriptionSwitch"></label>
                  </span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="newsLettersOptIn">Inscription aux newsletters</label>
                <div class="col-sm-8" style="text-align: left;">
                  <span class="custom-control custom-switch" style="display: inline" id="newsLettersOptIn">
                    <input type="checkbox" class="custom-control-input" id="newsLettersOptInCustomSwitch" v-model="person.newsletterOptIn">
                    <label class="custom-control-label" for="newsLettersOptInCustomSwitch"></label>
                  </span>
                </div>
              </div>
              <div class="col-md-12" style="text-align: left;">
                <h2>Adresse <span @click="editAddress=!editAddress" style="font-size:15px;cursor: pointer;"> <i class="fas fa-edit mb-1 ml-2"></i></span></h2>
                <div v-if="address && address.id && !editAddress">
                  <div class="col-md-12">
                    <label class="col-md-2"><strong>Adresse</strong></label>
                    <span class="col-md-10">{{address.streetNumber}} {{address.streetName}}</span>
                  </div>
                  <div class="col-md-12">
                    <label class="col-md-2"><strong>Ville</strong></label>
                    <span class="col-md-9">{{address.postalCode}} {{address.city}} / {{address.country}}</span>
                  </div>
                </div>
                <div v-else>
                  <div class="form-group">
                    <label for="streetNumberInput">Numéro</label>
                    <input type="text" class="form-control" id="streetNumberInput" v-model="address.streetNumber">
                  </div>
                  <div class="form-group">
                    <label for="streetNameInput">Nom de Rue</label>
                    <input type="text" class="form-control" id="streetNameInput" v-model="address.streetName">
                  </div>
                  <div class="form-group">
                    <label for="postalCodeInput">Code Postal</label>
                    <input type="number" class="form-control" id="postalCodeInput" v-model="address.postalCode">
                  </div>
                  <div class="form-group">
                    <label for="cityInput">Ville</label>
                    <input type="text" class="form-control" id="cityInput" v-model="address.city">
                  </div>
                  <div class="form-group">
                    <label for="countryInput">Pays</label>
                    <input type="text" class="form-control" id="countryInput" v-model="address.country">
                  </div>
                  <button class="btn btn-primary mb-2" @click="saveAddress">Enregistrer</button>
                </div>
              </div>
              <button
                v-if="person.firstName && person.lastName && person.email && person.phone && person.birthDate && person.cis
                  && person.membertype && this.address.id && !this.editAddress"
                type="submit"
                class="btn btn-primary"
                v-on:click="savePerson(false)">
                Valider
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="col-md-12 mr-1 box" v-if="cotisations">
        <h2>Paiment des cotisations ({{cotisations.length}})</h2>
        <div class="row mb-3" v-for="cotisation in cotisations" :key="cotisation.id">
          <div class="col-md-3">
            <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/resources/'+cotisation.id+'/file?token='+jwtToken" :alt="cotisation.name" style="max-height: 80px; margin-bottom: 20px;"/>
          </div>
          <div class="col-md-9">
            <div class="row" style="text-align: left;">
              <div class="col-md-4">
                <strong>Description:</strong>
              </div>
              <div class="col-md-8">
                <span v-html="cotisation.name"></span>
              </div>
            </div>
            <div class="row" style="text-align: left;">
              <div class="col-md-4">
                <strong>Prix:</strong>
              </div>
              <div class="col-md-8">
                <span>{{cotisation.price}} €</span>
              </div>
            </div>
          </div>
        </div>
        <button v-if="processingPayment" disabled name="btn" value="Payer la/les cotisation(s)" class="btn btn-primary mx-auto">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Payer la/les cotisation(s)
        </button>
        <button v-else class="btn btn-primary mx-auto" v-on:click="paySubscriptions()">Payer la/les cotisation(s)</button>
      </div>

    </div>

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'EmailValidation',
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      axiosInstanceUnsecure: state => state.AppStore.axiosInstanceUnsecure,
      jwtTokenStore: state => state.AppStore.jwtToken,
      stripeKey: state => state.AppStore.stripeKey
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'AppStore/updateUser'
    }),
    savePerson: function(){
      if(this.person.id){
        this.axiosInstanceUnsecure.patch('/users/'+this.person.id+'/unsecure?token='+this.jwtToken, this.person)
        .then(response => {
          this.person.subscriptions = response.data.subscriptions
          // JSON responses are automatically parsed.
          this.loadCotisation()
          this.$toasted.show("Utilisateur "+response.data.firstName+" "+response.data.lastName+" correctement mis à jour", {type: 'success'})
        })
        .catch(e => {
          this.$toasted.show("Erreur lors de la mise à jour", {type: 'error'})
        })
      }
    },
    paySubscriptions: function(){
      this.processingPayment = true

      let command = {}
      let reservations = []
      for(let cotisation of this.cotisations){
        reservations.push({resource: cotisation, quantity: 1})
      }
      command.reservations = reservations
      command.successURL = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort
      command.cancelURL = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort

      this.axiosInstanceUnsecure.post('/commands/adhesion?token='+this.jwtToken, command).then(response => {
        this.processingPayment = false
        if(response.data.stripeSessionId){
          this.stripe.redirectToCheckout({
            sessionId: response.data.stripeSessionId
          }).then(function (result) {
            if(result.error.message){
              this.$toasted.show('La commande a échoué '+result.error.message, {type: 'error'})
            }
          })
        }else{
          this.$toasted.show("La commande ne peut pas être effectuée", {type: 'error'})
        }
      })
      .catch(e => {
        this.processingPayment = false
        console.log(e)
        this.$toasted.show("La commande ne peut pas être effectuée", {type: 'error'})
      })
    },
    loadAccount(userid){
      this.axiosInstanceUnsecure.get("/users/"+userid+'/unsecure?token='+this.jwtToken).then(response => {
        this.person = response.data

        if(response.data._links && response.data._links.address){
          if(response.data._links.address.href){
            this.getAddress(""+response.data._links.address.href)
          }
        }

      }).catch(e => {
        if(e.response && e.response.status == 404){
          this.$toasted.show("Vous avez changé votre email.<br/>Utilisez le lien qui vous a été envoyé pour valider et payer votre cotisation", {type: 'info'})
        }else {
          console.log(e)
          this.$toasted.show("Le compte n°"+userid+" n'a pas pu être trouvé", {type: 'error'})
        }
      })

    },
    getAddress: function(addressPath){
      this.axiosInstanceUnsecure.get(addressPath+'/unsecure?token='+this.jwtToken).then(response => {
        this.editAddress= false
        this.address = response.data
      })
      .catch(e => {
        console.log(e)
        this.$toasted.show('Error '+e, {type: 'error'})
      })
    },
    saveAddress: function(){

      let newAddress = this.address
      newAddress.persons = [{id: this.person.id}]

      this.axiosInstanceUnsecure.post('/addresses/unsecure?token='+this.jwtToken, newAddress).then(response => {
        this.address = response.data
        this.editAddress = false
      })
      .catch(e => {
        console.log(e)
        this.$toasted.show('Error '+e, {type: 'error'})
      })
    },
    loadCotisation(){
      /* No more cotisation
      //jwtToken is a dummy token, just like validation token      
      this.axiosInstanceUnsecure.get('/resources/cotisation?token='+this.jwtToken).then(response => {
        if(!response.data || response.data.rows == 0){
          this.$toasted.show("Aucune cotisation disponible pour votre statut", {type: 'info'})
        }

        this.cotisations = response.data

      }).catch(e => {
        if(e.response.status == 404){
          this.$toasted.show("Aucune cotisation à payer", {type: 'info'})
        } else {
          this.$toasted.show("Impossible de charger le paiment de la cotisation ", {type: 'error'})
        }
      })
      //*/
    }
  },
  data: function() {
     return  {
        message: "",
        errorMessage: null,
        person: {address: {}, subscriptions: ["AMICALE"]},
        purchaseInfo: {amount: null, stubNumber: null},
        stripe: null,
        validationAddress: null,
        isValid: "inProgress",
        cotisations: null,
        jwtToken: null,
        address: {},
        editAddress: false,
        processingPayment: false
    }
  },
  mounted(){
    this.jwtToken = this.$route.query.token
    if(this.jwtToken){
      this.loadAccount(this.$route.query.userid)
    }
    this.stripe = Stripe(this.stripeKey)
  }
}
</script>
