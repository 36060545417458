<template>
  <div class="createUserDiv">

    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="form-group row">
            <label class="col-sm-4" for="firstNameInput">Prénom</label>
            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-user"></i></div>
              </div>
              <input type="text" class="form-control" id="firstNameInput" v-model="person.firstName">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="lastNameInput">Nom</label>
            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-user"></i></div>
              </div>
              <input type="text" class="form-control" id="lastNameInput" v-model="person.lastName">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="inputEmailCreate">Email</label>
            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text">@</div>
              </div>
              <input v-if="person.id && !hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})" readonly type="email" class="form-control" id="inputEmailCreate" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="person.email" required>
              <input v-else type="email" class="form-control" id="inputEmailCreate" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="person.email" required>
            </div>
            <small id="emailHelp" class="form-text text-muted mx-auto">Nous ne partagerons pas votre email</small>
          </div>
          <div v-if="!person.id" class="alert alert-warning" role="alert">
            Un email vous sera envoyé pour confirmer sa validité.
            Une fois que vous aurez confirmé votre mail, vous pourrez vous acquitter de votre cotisation pour qu'un administrateur de
            l'Amicale valide votre compte. Une fois votre compte validé, vous pourrez alors l'utiliser.
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="createInputPhone">Téléphone</label>
            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-phone"></i></div>
              </div>
              <input type="text" class="form-control" id="createInputPhone" v-model="person.phone" required>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="birthDateInput">Date de naissance</label>
            <div class="dropdown col-sm-8">
              <select class="custom-select col-sm-2" @change="updateBirthdate" v-model="birthdate.day">
                <option v-for="day in days" :id=day.day :value="day.value" :key="'dayKey'+day.day">{{day.day}}</option>
              </select>
              <select class="custom-select col-sm-6" @change="updateBirthdate" v-model="birthdate.month">
                <option value="01">Janvier</option>
                <option value="02">Février</option>
                <option value="03">Mars</option>
                <option value="04">Avril</option>
                <option value="05">Mai</option>
                <option value="06">Juin</option>
                <option value="07">Juillet</option>
                <option value="08">Août</option>
                <option value="09">Septembre</option>
                <option value="10">Octobre</option>
                <option value="11">Novembre</option>
                <option value="12">Décembre</option>
              </select>
              <select class="custom-select col-sm-4" @change="updateBirthdate" v-model="birthdate.year">
                <option v-for="year in years" :id=year :value="year" :key="year">{{year}}</option>
              </select>
            </div>
          </div>

          <!-- #############   GESTION AJOUT ENFANT   ###################### -->
          <div class="col-md-12" style="border-style: dotted; border-width: 1px 0px 1px 0px; padding-top: 5px; margin-bottom: 5px;">
            <h5 style="text-align: left;">Enfant(s) <span style="font-size:15px;cursor: pointer;" @click="addRelation()"><i class="fas fa-plus mb-1 ml-2"></i></span></h5>
            <br/>
            <div v-for="relation in localPerson.relations" :key="relation.relatedUser.firstName">
              <div class="form-group row">
                <label class="col-sm-4" for="relationFirstNameInput">Prénom</label>
                <input type="text" class="form-control col-sm-8" id="relationFirstNameInput" v-model="relation.relatedUser.firstName">
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="relationLastNameInput">Nom</label>
                <input type="text" class="form-control col-sm-8" id="relationLastNameInput" v-model="relation.relatedUser.lastName">
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="floorInput">Date de naissance</label>
                <div class="dropdown col-sm-8">
                  <select class="custom-select col-sm-2" @change="updateRelBirthdate(relation.relatedUser)" v-model="relation.relatedUser.splitBirthDate.day">
                    <option v-for="day in days" :id=day.day :value="day.value" :key="'days'+day.day">{{day.day}}</option>
                  </select>
                  <select class="custom-select col-sm-6" @change="updateRelBirthdate(relation.relatedUser)" v-model="relation.relatedUser.splitBirthDate.month">
                    <option value="01">Janvier</option>
                    <option value="02">Février</option>
                    <option value="03">Mars</option>
                    <option value="04">Avril</option>
                    <option value="05">Mai</option>
                    <option value="06">Juin</option>
                    <option value="07">Juillet</option>
                    <option value="08">Août</option>
                    <option value="09">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                  <select class="custom-select col-sm-4" @change="updateRelBirthdate(relation.relatedUser)" v-model="relation.relatedUser.splitBirthDate.year">
                    <option v-for="year in years" :id=year :value="year" :key="year">{{year}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- ###########   FIN GESTION AJOUT ENFANT   #################### -->

          <div class="form-group row">
            <label class="col-sm-4" >CIS d'appartenance</label>
            <div class="dropdown col-sm-8">
              <select class="custom-select" v-model="person.cis">
                <option value="Autre">0-Autre</option>
                <option value="Saint Georges">1-Saint Georges</option>
                <option value="Beauregard">2-Beauregard</option>
                <option value="Le Blosne">3-Le Blosne</option>
                <option value="CTA-CODIS">4-CTA/CODIS</option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="!person.id || hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})">
            <label class="col-sm-4" >Statut</label>
            <div class="dropdown col-sm-8">
              <select class="custom-select" v-model="person.membertype" @change="updateSubscription">
                <option value="SPP">SPP</option>
                <option value="SPV">SPV</option>
                <option value="HONORAIRE">HONORAIRE</option>
                <option value="ADMINISTRATIF">ADMINISTRATIF</option>
                <option value="EXTERNE">EXTERNE</option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="person.membertype=='EXTERNE'">
            <label class="col-sm-4" for="externPrecisionInput">Précisez (externe)</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" id="externPrecisionInput" v-model="person.membertypePrecision">
            </div>
          </div>
          <div class="form-group row" v-if="!person.id || hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})">
            <label class="col-sm-4">Adhésions</label>
            <div class="col-sm-8" style="text-align: left">
              <div class="form-check form-check-inline" v-if="person.membertype=='HONORAIRE'">
                <input disabled class="form-check-input" type="checkbox" id="HONORAIRE" value="HONORAIRE" v-model="person.subscriptions">
                <label class="form-check-label" for="HONORAIRE">HONORAIRE</label>
              </div>
              <div class="form-check form-check-inline" v-else>
                <input disabled class="form-check-input" type="checkbox" id="AMICALE" value="AMICALE" v-model="person.subscriptions">
                <label class="form-check-label" for="AMICALE">AMICALE</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="UDSP" value="UDSP" v-model="person.subscriptions">
                <label class="form-check-label" for="UDSP">UDSP</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="FNSPF" value="FNSPF" v-model="person.subscriptions">
                <label class="form-check-label" for="FNSPF">FNSPF (FEDE)</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="newsLettersOptIn">Adhérent UD/FNSPF en double statut SPV/SPP</label>
            <div class="col-sm-8" style="text-align: left;">
              <span class="custom-control custom-switch" style="display: inline" id="udFnspfDoubleStatusSubscription">
                <input type="checkbox" class="custom-control-input" id="udFnspfDoubleStatusSubscriptionSwitch" v-model="person.udFnspfDoubleStatusSubscription">
                <label class="custom-control-label" for="udFnspfDoubleStatusSubscriptionSwitch"></label>
              </span>
            </div>
          </div>
          <div class="form-group row" v-if="!person.id || hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})">
            <label class="col-sm-4" for="newsLettersOptIn">Inscription aux newsletters</label>
            <div class="col-sm-8" style="text-align: left;">
              <span class="custom-control custom-switch" style="display: inline" id="newsLettersOptIn">
                <input type="checkbox" class="custom-control-input" id="newsLettersOptInCustomSwitch" v-model="person.newsletterOptIn">
                <label class="custom-control-label" for="newsLettersOptInCustomSwitch"></label>
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4" for="exampleInputPassword1">
              Mot de passe
              <span v-b-popover.hover="popoverConfig">
                <i class="fas fa-info-circle"></i>
              </span>
            </label>

            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-key"></i></div>
              </div>
              <input type="password" class="form-control" id="passwordInput" aria-describedby="passwordHelp" placeholder="Password" v-model="person.password">
            </div>
            <small v-if="person.id && person.id>0" id="passwordHelp" class="form-text text-muted mx-auto">Laisser vide pour ne pas modifier</small>
            <small v-if="!passwordAuthorized" id="passwordHelp2" class="form-text mx-auto" style="color: red">Mot de passe non autorisé</small>
          </div>
          <div class="form-group row" v-if="person.password">
            <label class="col-sm-4" for="exampleInputPassword1">Répéter le mot de passe</label>
            <div class="input-group col-sm-8">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-key"></i></div>
              </div>
              <input type="password" class="form-control" id="passwordInput" placeholder="Password" v-model="person.password2">
            </div>
            <small v-if="person.password && person.password2 && person.password!=person.password2" id="passwordHelp3" class="form-text mx-auto" style="color: red">Les mots de passe ne correspondent pas</small>
          </div>

          <button
            v-if="processing"
            type="submit"
            class="btn btn-primary"
            disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Enregistrement
          </button>
          <div class="form-group row"
            v-else-if="person.firstName && person.lastName && person.email && person.phone && person.birthDate && person.cis
              && person.membertype && (person.id || person.password && person.password2 && person.password === person.password2 && passwordAuthorized)">
            <span class="mx-auto">
              <vue-recaptcha
                @verify="onCaptchaVerified"
                sitekey="6LcCyrsUAAAAAAl-zF0oTZnUyMV8o_IbMO86BPPS">
              </vue-recaptcha>
            </span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CreateUser',
  props: {
    msg: String,
    parentPerson: Object
  },
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      axiosInstance: state => state.AppStore.axiosInstance,
      axiosInstanceUnsecure: state => state.AppStore.axiosInstanceUnsecure
    }),
    ...mapGetters({
      hasRole: 'AppStore/hasRole'
    }),
    person: function(){
      if(this.parentPerson && this.parentPerson.id) {
        if(this.birthdate.year == 1900 && this.parentPerson.birthDate && this.parentPerson.birthDate.year) {
          this.birthdate = {year: this.parentPerson.birthDate.year, month: this.parentPerson.birthDate.month, day: this.parentPerson.birthDate.day}
        }
        return this.parentPerson
      }

      return this.localPerson
    },
    years: function(){
      let years = []
      let curYear = new Date().getUTCFullYear()
      for(let i=1900; i<=curYear; i++){
        years.push(i)
      }
      return years
    },
    days: function(){
      let days = []
      for(let i=1;i<=9;i++){
        days.push({value: '0'+i, day: i})
      }
      for(let i=10;i<=31;i++){
        days.push({value: i, day: i})
      }
      return days
    },
    passwordAuthorized: function(){
      if(this.person && this.person.password){
        let mostUsedPasswords = ['123456',
                                'password',
                                '123456789',
                                '12345678',
                                '12345',
                                '111111',
                                '1234567',
                                'sunshine',
                                'qwerty',
                                'azerty',
                                'iloveyou',
                                'princess',
                                'princesse',
                                'admin',
                                'welcome',
                                '666666',
                                'abc123',
                                'football',
                                '123123',
                                'monkey',
                                '654321',
                                '!@#$%^&*',
                                'charlie',
                                'aa123456',
                                'donald',
                                'password1',
                                'qwerty123',
                                'azerty123'
                              ]
        let darknet2017 = [
          '123456',
          '123456789',
          '111111',
          'password',
          'qwerty',
          'azerty',
          'abc123',
          '12345678',
          'password1',
          '1234567',
          '123123',
          '1234567890',
          '000000',
          '12345',
          'iloveyou',
          '1q2w3e4r5t',
          '1a2z3e4r5t',
          '1234',
          '123456a',
          'qwertyuiop',
          'azertyuiop',
          'monkey',
          '123321',
          'dragon',
          '654321',
          '666666',
          '123',
          'myspace1',
          'a123456',
          '121212',
          '1qaz2wsx',
          '123qwe',
          '123aze',
          '123abc',
          'tinkle',
          'target123',
          'gwerty',
          '1g2w3e4r',
          'gwerty123',
          'zag12wsx',
          '7777777',
          'qwerty1',
          'azerty1',
          '1q2w3e4r',
          '1a2z3e4r',
          '987654321',
          '222222',
          'qwe123',
          'aze123',
          'qwerty123',
          'azerty123',
          'zxcvbnm',
          '555555',
          '112233',
          'fuckyou',
          'asdfghjkl',
          '12345a',
          '123123123',
          '1q2w3e',
          '1a2z3e',
          'qazwsx',
          'computer',
          'aaaaaa',
          '159753',
          'iloveyou1',
          'fuckyou1',
          '789456123',
          '11111111',
          '123654',
          'princess1',
          'princesse1',
          '888888',
          'linkedin',
          'michael',
          'sunshine',
          'football',
          '11111',
          '777777',
          '1234qwer',
          '123azer',
          '999999',
          'j38ifUbn',
          'monkey1',
          'football1',
          'daniel',
          'azerty',
          'a12345',
          '123456789a',
          '789456',
          'asdfgh',
          'love123',
          'abcd1234',
          'jordan23',
          '88888888',
          '5201314',
          '12qwaszx',
          'FQRG7CS493',
          'ashley',
          'asdf',
          'asd123',
          'superman',
          'jessica',
          'love',
          'samsung',
          'shadow',
          'blink182',
          '333333',
          'michael1',
          'babygirl1'
        ]

        return this.person.password.length>5 && !mostUsedPasswords.includes(this.person.password) && !darknet2017.includes(this.person.password)

      }
      return true
    },
    popoverConfig() {
      return {
        html: true,
        title: () => {
          return 'Recommandations'
        },
        content: () => {
          return 'Vous ne devez pas choisir un mot de passe dans la liste des mots de passe connus: '+
          '<a href="https://fr.wikipedia.org/wiki/Liste_des_mots_de_passe_les_plus_courants">wikipedia</a><br />'+
          'Un mot de passe fort est facile à retenir et contient plus de 10 caractères, par exemple: jadorelesitedelamicaledespompiers'
        }
      }
    }
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      //this.processing = true
      if(!this.person.id && this.person.password === this.person.password2){
        //this.person.birthDate = "2019-03-28T15:54:31.038Z"
        this.person.recaptcha = recaptchaToken
        this.axiosInstanceUnsecure.post('/users', this.person)
        .then(response => {
          this.$toasted.show("Compte correctement créé. Merci de valider votre email et de régler votre cotisation avec le lien qui vous a été envoyé", {type: 'success'})
          this.processing = false
        }).catch(e => {
          if(e.response.status==403)
            this.$toasted.show("L'e-mail choisi est déjà utilisé", {type: 'error'})
          else {
            this.$toasted.show("Une erreur est survenue. Veuillez vérifier les champs renseignés", {type: 'error'})
          }
          this.processing = false
        })
      }else if(this.person.id && (!this.person.password || this.person.password === this.person.password2)){
        this.updateBirthdate()
        this.axiosInstance.patch('/users/'+this.person.id, this.person)
        .then(response => {
          // JSON responses are automatically parsed.
          this.$toasted.show("Utilisateur "+response.data.firstName+" "+response.data.lastName+" correctement mis à jour", {type: 'success'})
          this.processing = false
        })
        .catch(e => {
          this.$toasted.show("L'e-mail choisi est déjà utilisé", {type: 'error'})
          this.processing = false
        })
      }else{
        this.$toasted.show("Les mots de passe ne correspondent pas", {type: 'error'})
        this.processing = false
      }
    },
    updateSubscription(){
      if(this.person.membertype == 'HONORAIRE'){
        let index = this.person.subscriptions.indexOf('AMICALE');
        if (index > -1) {
           this.person.subscriptions.splice(index, 1);
        }
        if(this.person.subscriptions.indexOf('HONORAIRE'<0))
          this.person.subscriptions.push('HONORAIRE')
      }else{
        let index = this.person.subscriptions.indexOf('HONORAIRE');
        if (index > -1) {
           this.person.subscriptions.splice(index, 1);
        }
        if(this.person.subscriptions.indexOf('AMICALE'<0))
          this.person.subscriptions.push('AMICALE')
      }
    },
    updateBirthdate(){
      if(this.parentPerson && this.parentPerson.id)
        this.parentPerson.birthDate = this.birthdate.year+"-"+this.birthdate.month+"-"+this.birthdate.day
      else
        this.localPerson.birthDate = this.birthdate.year+"-"+this.birthdate.month+"-"+this.birthdate.day
    },
    addRelation(){
      this.localPerson.relations.push({
        relatedUser: {
          firstName: '',
          lastName: '',
          splitBirthDate: {year: 1900, month: '01', day: '01'},
          birthDate: '1900-01-01'
        },
        relationShipPrecision: 'Enfant',
        relationShip: 'FAMILY'
      })
    },
    updateRelBirthdate(relatedUser){
      relatedUser.birthDate = relatedUser.splitBirthDate.year+"-"+relatedUser.splitBirthDate.month+"-"+relatedUser.splitBirthDate.day
    }
  },
  data: function() {
     return  {
      message: "",
      errors: [],
      localPerson: {newsletterOptIn: true, subscriptions:[], relations: []},
      processing: false,
      birthdate: {year: 1900, month: '01', day: '01'}
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>

