
const state = {
  commands: [],
  specLoadedCommands: {},
  isSearching: []
}

const mutations = {
  updateList(state, _commands){
    state.commands = _commands
  },
  updateSpecLoadedCommands(state, _command){
    const tmpArr = state.specLoadedCommands;
    state.specLoadedCommands = [];
    tmpArr[_command.id] = _command;
    state.specLoadedCommands = tmpArr;
  }
}

const actions = {
  loadCommands({commit, rootState}, filterSort){

    let filters = ''
    if(filterSort){
      filters = '?'
      if(filterSort.filters){
          filters += filterSort.filters
          if(filterSort.sort)
            filters += '&'+filterSort.sort
      }else if(filterSort.sort){
        filters += filterSort.sort
      }
    }

    rootState.AppStore.axiosInstance.get('/commands'+filters).then(response => {
      // JSON responses are automatically parsed.
      if(response.data._embedded) {
        commit('updateList', response.data._embedded.data)
      }
    })
    .catch(e => {
      console.log(e)
    })
  },
  loadCommand({state, commit, rootState}, commandId){
    if(!state.isSearching.includes(commandId) && !state.specLoadedCommands[commandId]){
      state.isSearching.push(commandId)
      rootState.AppStore.axiosInstance.get('/commands/'+commandId).then(response => {
        // JSON responses are automatically parsed.
        commit('updateSpecLoadedCommands', response.data)
        state.isSearching.splice( state.isSearching.indexOf(commandId), 1 )
      })
      .catch(e => {
        console.log(e)
        commit('updateSpecLoadedCommands', {id: commandId})
        state.isSearching.splice( state.isSearching.indexOf(commandId), 1 )
      })
    }
  }
}

const getters = {
  getCommandFromId: (state) => (loader) => {
    let command = state.commands.find(command => command.id == loader.id)
    if(!command)
      command = state.specLoadedCommands[loader.id]
    if(!command){
      if(loader.callback)
        loader.callback(loader.id)
      return {id: loader.id}
    }
    return command
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
