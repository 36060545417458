import Vue from 'vue'
import Vuex from 'vuex'

import AppStore from './AppStore'
import Cart from './Cart'
import CommandStore from './CommandStore'
import CommissionStore from './CommissionStore'
import NewsStore from './NewsStore'
import PersonStore from './PersonStore'
import ReservationStore from './ReservationStore'
import ResourceStore from './ResourceStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AppStore,
    Cart,
    CommandStore,
    CommissionStore,
    NewsStore,
    PersonStore,
    ReservationStore,
    ResourceStore
  }
})
