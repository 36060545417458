<template>
  <div class="calendarPurchaseDiv">

    <div class="modal fade" id="rgpdModal" tabindex="-1" role="dialog" aria-labelledby="rgpdModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Information sur les données personnelles</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-html="rgpdInfo">
          </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
            </div>
        </div>
      </div>
    </div>

    <div class="row mt-n2">
      <div class="col-12">
        <div class="col-12 box">

          <h1>
            Achat du Calendrier 2023
          </h1>

          <div>
            <h2>
              Informations Personnelles
              <span style="font-size:18px;cursor: pointer; float:right" data-toggle="modal" data-target="#rgpdModal"><i class="fas fa-info-circle"></i></span>
            </h2>
            <div class="form-group">
              <label for="calendarLastNameInput">Nom*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-user"></i></div>
                </div>
                <input type="text" class="form-control" id="calendarLastNameInput" v-model="person.lastName" required>
              </div>
            </div>
            <div class="form-group">
              <label for="calendarFirstNameInput">Prénom*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-user"></i></div>
                </div>
                <input type="text" class="form-control" id="calendarFirstNameInput" v-model="person.firstName" required>
              </div>
            </div>
            <div class="form-group">
              <label for="inputEmailPurchase">email*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">@</div>
                </div>
                <input type="email" class="form-control" id="inputEmailPurchase" aria-describedby="emailHelp" placeholder="Entrer votre email"
                  v-model="person.email" required>
              </div>
              <small v-if="!validateEmail()" id="emailHelp" class="form-text mx-auto" style="color:red;">email invalide</small>
            </div>
            <h2>Adresse</h2>
            <div class="form-group">
              <label for="streetNumberInput">Numéro*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-home"></i></div>
                </div>
                <input type="text" class="form-control" id="streetNumberInput" v-model="person.address.streetNumber" required>
              </div>
            </div>
            <div class="form-group">
              <label for="streetNameInput">Nom de Rue*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-home"></i></div>
                </div>
                <input type="text" class="form-control" id="streetNameInput" v-model="person.address.streetName" required>
              </div>
            </div>
            <div class="form-group">
              <label for="postalCodeInput">Code Postal*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-home"></i></div>
                </div>
                <input type="number" class="form-control" id="postalCodeInput" v-model="person.address.postalCode" required>
              </div>
            </div>
            <div class="form-group">
              <label for="cityInput">Ville*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-home"></i></div>
                </div>
                <input type="text" class="form-control" id="cityInput" v-model="person.address.city" required>
              </div>
            </div>
            <h2>Informations de paiement*</h2>
            <div class="form-group">
              <label for="stubNumberInput">N° de souche*</label>
              <input type="number" class="form-control" id="stubNumberInput" v-model="purchaseInfo.stubNumber">
            </div>
            <div class="form-group">
              <label for="exampleInputPrice">Montant</label>&nbsp;
                <button type="button" class="btn btn-outline-primary btn-sm" @click="setAmount(5)">5€</button>&nbsp;
                <button type="button" class="btn btn-outline-primary btn-sm" @click="setAmount(10)">10€</button>&nbsp;
                <button type="button" class="btn btn-outline-primary btn-sm" @click="setAmount(20)">20€</button>&nbsp;
                <button type="button" class="btn btn-outline-primary btn-sm" @click="setAmount(50)">50€</button>
              <input type="number" min="2" class="form-control form-control-sm" id="exampleInputPrice" placeholder="Ou Entrez un montant libre" v-model="purchaseInfo.amount" required>
            </div>
            <button
              v-if="processing"
              type="submit"
              class="btn btn-primary"
              disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Traitement en cours
            </button>
            <div v-else-if="!validateEmail()" class="alert alert-danger" role="alert">
                e-mail invalide
            </div>
            <div v-else-if="person.lastName && person.firstName && person.email
                              && person.address.streetNumber && person.address.streetName && person.address.postalCode && person.address.city
                              && purchaseInfo.amount && purchaseInfo.stubNumber"
            class="form-group row">
              <span class="mx-auto">
                <vue-recaptcha
                  @verify="onCaptchaVerified"
                  sitekey="6LcCyrsUAAAAAAl-zF0oTZnUyMV8o_IbMO86BPPS">
                </vue-recaptcha>
              </span>
            </div>
            <div v-else class="alert alert-danger" role="alert">
              Tous les champs marqués d'une étoile (*) sont obligatoires
            </div>
            <!-- <button class="btn btn-primary" v-on:click="purchaseCalendar()">Payer</button> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha'
import { mapState } from 'vuex'

export default {
  name: 'CalendarPurchase',
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      rgpdInfo: state => state.AppStore.rgpdHTML,
      stripeKey: state => state.AppStore.stripeKey
    })
  },
  methods: {
    validateEmail: function()
    {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.person.email))
      {
        return (true)
      }
        //alert("Email invalide")
        return (false)
    },
    onCaptchaVerified: function (recaptchaToken) {
      if(this.validateEmail()){
        this.processing = true
        this.purchaseInfo.recaptcha = recaptchaToken
        this.purchaseCalendar()
      }
    },
    purchaseCalendar: function(){
      this.purchaseInfo.person = this.person
      this.purchaseInfo.successRedirect = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort
      this.axios.post(this.accessProtocol+'//'+this.accessIp+':'+this.accessPort+'/FireFighters/aspr/v1/commands/calendar', this.purchaseInfo)
      .then(response2 => {
        this.processing = false
        this.stripe.redirectToCheckout({
          sessionId: response2.data.stripeSessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          if(result.error.message){
            this.$toasted.show('La commande a échoué '+result.error.message, {type: 'error'})
          }
        });
      })
      .catch(e => {
        this.processing = false
        console.log(e)
        this.$toasted.show("La commande ne peut pas être effectuée", {type: 'error'})
      })
    },
    setAmount(amount){
      this.purchaseInfo.amount = amount
    }
  },
  data: function() {
     return  {
       message: "",
        errors: [],
        person: {address: {}},
        purchaseInfo: {amount: null, stubNumber: null},
        stripe: null,
        processing: false
    }
  },
  mounted() {
    this.stripe = Stripe(this.stripeKey)
  },
  components: {
    VueRecaptcha
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
