
const state = {
  news: []
}

const mutations = {
  updateList(state, _news){
    state.news = _news
  }
}

const actions = {
  loadNews({commit, rootState}, filterSort){

    let filters = ''
    if(filterSort){
      filters = '?'
      if(filterSort.filters){
          filters += filterSort.filters
          if(filterSort.sort)
            filters += '&'+filterSort.sort
      }else if(filterSort.sort){
        filters += filterSort.sort
      }
    }

    rootState.AppStore.axiosInstance.get('/news'+filters).then(response => {
      // JSON responses are automatically parsed.
      if(response.data._embedded) {
        commit('updateList', response.data._embedded.data)
      }
    })
    .catch(e => {
      console.log(e)
    })
  }
}

const getters = {
  getNewsFromId: (state) => (loader) => {
    let news = state.news.find(news => news.id == loader.id)
    if(!news){
      if(loader.callback)
        loader.callback(loader.id)
      return {id: 0}
    }
    return news
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
