<template>
  <div class="recapCartDiv">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 box">
          <h2>Dernier élément ajouté au panier</h2>

          <div class="row" v-if="lastReservation && lastReservation.resource">
            <div class="col-md-6">
              <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/resources/'+lastReservation.resource.id+'/file?token='+jwtToken" :alt="lastReservation.resource.name" style="max-height: 120px;"/>
            </div>
            <div class="col-md-6 mt-4">
              {{lastReservation.resource.name}}
              <br>
              <strong> {{getPrice(lastReservation)}} € </strong>
            </div>
          </div>
          <br/>
          <h3 style="text-align: left;border-bottom: solid;border-width: 1px;">Total Panier </h3>
          <strong>{{reservations.length}} article(s): {{totalPrice}} €</strong>
          <br/>
          <br/>
          <button class="btn btn-primary" v-on:click="goToCart(true)">Aller au panier</button>&nbsp;
          <button class="btn btn-secondary" v-on:click="showRecapCart(false)">Fermer</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'RecapCart',
  props: {
    showRecapCart: Function
  },
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      commandsList: state => state.CommandsStore.commands,
      reservations: state => state.Cart.reservations,
      axiosInstance: state => state.AppStore.axiosInstance,
      jwtToken: state => state.AppStore.jwtToken,
      userName: state => state.AppStore.userName,
      personId: state => state.AppStore.userId
    }),
    ...mapGetters([
      //'commissionList'
    ]),
    totalPrice: function(){
      let totalPrice = 0
      for(let reservation of this.reservations){

        let duration = 1
        if(reservation.resource.billingPeriod=="DAY" || reservation.resource.billingPeriod=="ITEM_DAY"){
          if(reservation.startDate && reservation.endDate){
            let date1 = new Date(reservation.startDate);
            let date2 = new Date(reservation.endDate);
            let diffTime = Math.abs(date1.getTime() - date2.getTime());
            duration = 1+Math.floor(diffTime / (1000 * 60 * 60 * 24));
          }
        }

        let quantity = reservation.quantity
        if(reservation.resource.billingPeriod=="DAY")
          quantity = 1

        totalPrice += reservation.resource.price*duration*quantity
      }
      return totalPrice
    },
    lastReservation: function(){
      if(this.reservations && this.reservations.length>0)
        return this.reservations[this.reservations.length-1]
      else
        return {}
    }
  },
  methods: {
    ...mapActions({
      removeFromCart: 'Cart/removeFromCart',
      clearCart: 'Cart/clearCart'
    }),
    getPrice(reservation){

      let duration = 1
      if(reservation.resource.billingPeriod=="DAY" || reservation.resource.billingPeriod=="ITEM_DAY"){
        if(reservation.startDate && reservation.endDate){
          let date1 = new Date(reservation.startDate);
          let date2 = new Date(reservation.endDate);
          let diffTime = Math.abs(date1.getTime() - date2.getTime());
          duration = 1+Math.floor(diffTime / (1000 * 60 * 60 * 24));
        }
      }

      let quantity = reservation.quantity
      if(reservation.resource.billingPeriod=="DAY")
        quantity = 1

      return reservation.resource.price*duration*quantity
    },
    goToCart: function(){
      this.showRecapCart(false)
      this.$router.push({name:'cart'})
    },
  },
  data: function() {
     return  {
      message: "",
      errors: [],
      resourcePictures: {},
      commandOrReserve: ''
    }
  },
  mounted() {
    //this.getReductions()
  }
}
</script>
