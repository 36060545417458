<template>
  <div class="emailValidationDiv">

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 mr-1 box">
          <h1>Vérification de l'email</h1>
          Votre email est <span v-if="isValid=='inProgress' && !jwtToken">en cours de validation</span>
          <div v-if="isValid=='inProgress' && !jwtToken" class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span v-else-if="isValid==false">
            non validé
            <font-awesome-icon icon="times-circle" style="color: red;"/>&nbsp;
            <div class="alert alert-danger" role="alert">
              {{errorMessage}}
            </div>
          </span>
          <span v-if="isValid==true || jwtToken">
            validé
            <font-awesome-icon icon="check-circle" style="color: #2ECC40;"/>
            <br/>
            Un administrateur va maintenant vérifier et valider votre compte (24/48h)
          </span>
        </div>
      </div>
    </div>

    <div class="row" v-if="cotisations">
      <div class="col-md-12">
        <div class="col-md-12 mr-1 box">
          <h2>Paiment des cotisations ({{cotisations.length}})</h2>
          <div v-for="cotisation in cotisations" :key="cotisation.id">
            <div class="col-md-3">
              <img class="img-fluid" :src="accessProtocol+'//'+accessIp+':'+accessPort+'/FireFighters/aspr/v1/resources/'+cotisation.id+'/file?token='+jwtToken" :alt="cotisation.name" style="max-height: 80px; margin-bottom: 20px;"/>
            </div>
            <div class="col-md-9">
              <div class="row" style="text-align: left;">
                <div class="col-md-4">
                  <strong>Description:</strong>
                </div>
                <div class="col-md-8">
                  <span v-if="cotisation.description" v-html="cotisation.description"></span>
                    <span v-else v-html="cotisation.name"></span>
                </div>
              </div>
              <div class="row" style="text-align: left;">
                <div class="col-md-4">
                  <strong>Prix:</strong>
                </div>
                <div class="col-md-8">
                  <span>{{cotisation.price}} €</span>
                </div>
              </div>
            </div>
            <br/>
          </div>
          <button v-if="processingPayment" disabled name="btn" value="Payer la/les cotisation(s)" class="btn btn-primary mx-auto">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Payer la/les cotisation(s)
          </button>
          <button v-else class="btn btn-primary mx-auto" v-on:click="paySubscriptions()">Payer la/les cotisation(s)</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'EmailValidation',
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      axiosInstanceUnsecure: state => state.AppStore.axiosInstanceUnsecure,
      stripeKey: state => state.AppStore.stripeKey
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'AppStore/updateUser'
    }),
    paySubscriptions: function(){
      this.processingPayment = true

      let command = {}
      let reservations = []
      for(let cotisation of this.cotisations){
        reservations.push({resource: cotisation, quantity: 1})
      }
      command.reservations = reservations
      command.successURL = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort
      command.cancelURL = this.accessProtocol+'//'+this.accessIp+':'+this.accessPort

      this.axiosInstanceUnsecure.post('/commands/adhesion?token='+this.jwtToken, command).then(response => {
        this.processingPayment = false
        if(response.data.stripeSessionId){
          this.stripe.redirectToCheckout({
            sessionId: response.data.stripeSessionId
          }).then(function (result) {
            if(result.error.message){
              this.$toasted.show('La commande a échoué '+result.error.message, {type: 'error'})
            }
          })
        }else{
          this.$toasted.show("La commande ne peut pas être effectuée", {type: 'error'})
        }
      })
      .catch(e => {
        this.processingPayment = false
        console.log(e)
        this.$toasted.show("La commande ne peut pas être effectuée", {type: 'error'})
      })
    },
    loadCotisation(){
      /* No more cotisation
      //jwtToken is a dummy token, just like validation token
      this.axiosInstanceUnsecure.get('/resources/cotisation?token='+this.jwtToken).then(response => {
        if(!response.data || response.data.rows == 0){
          this.$toasted.show("Aucune cotisation disponible pour votre statut", {type: 'info'})
        }

        this.cotisations = response.data

      }).catch(e => {
        if(e.response.status == 404){
          this.$toasted.show("Aucune cotisation à payer", {type: 'info'})
        } else {
          this.$toasted.show("Impossible de charger le paiment de la cotisation ", {type: 'error'})
        }
      })//*/
    }
  },
  data: function() {
     return  {
        message: "",
        errorMessage: null,
        purchaseInfo: {amount: null, stubNumber: null},
        stripe: null,
        validationAddress: null,
        isValid: "inProgress",
        cotisations: null,
        jwtToken: null,
        processingPayment: false
    }
  },
  mounted(){
    let validationToken = null
    this.stripe = Stripe(this.stripeKey)
    if(this.$route.params.token){
      validationToken = +this.$route.params.token
    }else if(window.location.href.includes("/validateorsubscribe?token")){
      validationToken = window.location.href.substring(window.location.href.lastIndexOf('=') + 1)
    }
    if(validationToken){
      this.axiosInstanceUnsecure.post('/auth/validate?token='+validationToken).then(response => {
        this.isValid = true
        this.message = "E-mail confirmé"

        let authenticatedResult = response.data
        this.jwtToken=authenticatedResult.token
        this.loginMessage = ''

        this.loadCotisation()

      }).catch(e => {
        this.isValid = false
        this.errorMessage="Le token fourni n'a pas pu être validé"
      })
    }else if(this.jwtToken){
      this.loadCotisation()
    }
  }
}
</script>

<style scoped>

</style>
