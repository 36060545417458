const state = {
  reservations: []
}

const mutations = {
  addReservation(state, resa){
    state.reservations.push(resa)
  },
  removeReservation(state, resa){
    for( let i = 0; i < state.reservations.length; i++){
     if ( state.reservations[i] == resa) {
       state.reservations.splice(i, 1);
     }
   }
 },
 removeAll(state){
   state.reservations = []
 }
}

const actions = {
  addToCart({ commit }, resa){
    commit('addReservation', resa)
  },
  removeFromCart({ commit }, resa){
    commit('removeReservation', resa)
  },
  clearCart({commit}){
    commit('removeAll')
  }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
