
const state = {
  reservations: [],
  specLoadedReservations: {},
  isSearching: new Map()
}

const mutations = {
  updateList(state, _reservations){
    state.reservations = _reservations
  },
  updateSpecLoadedReservations(state, _reservation){
    const tmpArr = state.specLoadedReservations;
    state.specLoadedReservations = [];
    tmpArr[_reservation.id] = _reservation;
    state.specLoadedReservations = tmpArr;
  }
}

const actions = {
  loadReservations({commit, rootState}, filterSort){

    let filters = ''
    if(filterSort){
      filters = '?'
      if(filterSort.filters){
          filters += filterSort.filters
          if(filterSort.sort)
            filters += '&'+filterSort.sort
      }else if(filterSort.sort){
        filters += filterSort.sort
      }
    }
    rootState.AppStore.axiosInstance.get('/reservations'+filters).then(response => {
        // JSON responses are automatically parsed.
        if(response.data._embedded) {
          commit('updateList', response.data._embedded.data)
        }
        if(filterSort && filterSort.callback){
          filterSort.callback()
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  loadReservation({state, commit, rootState}, reservationId){
    if(!state.isSearching.has(reservationId) && !state.specLoadedReservations[reservationId]){
      if(reservationId!=0){
        const promise = rootState.AppStore.axiosInstance.get('/reservations/'+reservationId);
        state.isSearching.set(reservationId, promise)
        promise.then(response => {
          commit('updateSpecLoadedReservations', response.data)
          state.isSearching.delete(reservationId)
        })
        .catch(e => {
          console.log(e)
          commit('updateSpecLoadedReservations', {id: reservationId})
          state.isSearching.delete(reservationId)
        })

        return promise;
      }
    } else if(state.isSearching.has(reservationId)){
      return state.isSearching.get(reservationId);
    } else {
      return new Promise((resolve, reject) => {
        resolve({data: state.specLoadedReservations[reservationId]})
      })
    }
  }
}

const getters = {
  getReservationFromId: (state) => (loader) => {
    let resource = state.reservations.find(reservation => reservation.id == loader.id)
    if(!resource)
      resource = state.specLoadedReservations[loader.id]

    if(!resource){
      if(loader.callback) {
        loader.callback(loader.id)
      }
      return {id: loader.id}
    }
    return resource
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
